@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Taviraj:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');

.loyalty__card-container {
    background-color: rgb(64, 187, 192);

}

.loyalty__card-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 3em 0em;
}

.loyalty__card-left {
    max-width: 30em;
    background-color: rgb(68, 181, 185);
    padding: 2em 2em;
    border: 1px solid rgb(89, 89, 89);
    font-weight: 500;
    border-radius: 5px;
    margin-left: 2em;
    margin-right: 2em;
    box-shadow: 0 0 15px black;
    padding-bottom: 4em;

}

.loyalty__card-left:hover {
    background-color: rgba(196, 196, 196, 0.155);
}

.loyalty__card-left li {
    margin-bottom: 1em;
}

.loyalty__card-right img {
    height: 10em;
    max-width: 20em;
    margin-left: 1em;
    border: 2px solid rgb(71, 71, 71);
    box-shadow: 0 0 15px rgba(65, 65, 65, 0.452);

}

.loyalty__card-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.loyalty__card-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 2em;
    margin-right: 2em;
}

.loyalty__card-right ul {
    font-weight: 600;
    margin-top: 1em;

}



.loyalty__card-info {
    display: flex;
    flex-direction: row;
    margin-top: 1em;

}

.loyalty__card-info p {
    margin-left: 1.5em;
    line-height: 1.3em;
    font-weight: 600;
}

.slide-right {
    opacity: 0;
    transform: translate(-50%);
    transition: opacity .3s ease-in, transform .3s ease-in;
}

.slide-right.slide-right-appear {
    opacity: 1;
    transform: translate(0);
}

.slide-up {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity .3s ease-in, transform .3s ease-in;
}

.slide-up.slide-up-appear {
    opacity: 1;
    transform: translate(0);
}

.rotate {
    opacity: 0;
    transform: rotateY(150deg);
    transition: opacity .3s ease-in, transform .5s ease-in;
}

.rotate.rotate-appear {
    opacity: 1;
    transform: rotateY(0);
}


@media screen and (max-width:1370px) {
    .loyalty__card-left {
        max-width: 19em;
        max-height: 13em;
    }
}

@media screen and (max-width:1195px) {
    .loyalty__card-left {
        max-width: 30em;
        max-height: 6em;
        margin-bottom: 2em;
    }

    .loyalty__card-images img {
        margin-left: 1em;
        margin-right: 1em;
    }

    .loyalty__card-right ul {
        margin-top: 0;
    }

    .loyalty__card-right li:last-child {
        margin-top: 0.5em;
    }
}

@media screen and (max-width:652px) {
    .loyalty__card-left {
        max-width: 30em;
        max-height: 10em;
        margin-bottom: 2em;
    }

    .loyalty__card-images img {
        margin-bottom: 2em;

    }
}

@media screen and (max-width:400px) {
    .loyalty__card-left {

        max-height: 13em;

    }
}