.cookie-banner {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
    width: 100%;
    max-width: 840px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: #4a5568;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 5;
}

.cookie-banner-buttons {
    display: flex;
}

.cookie-banner-text p {
    color: white;
    font-size: .9em;
}

.cookie-banner-text a {
    text-decoration: none;
    color: white;
}

.cookie-banner-text span {
    color: rgb(74, 219, 251);
}

.cookie-banner button {
    padding: 0.5rem 1.25rem;
    color: #CBD5E0;
    border-radius: 0.375rem;
    border: 1px solid #2D3748;

}

.decline-btn {
    background-color: transparent;
    border: none;
    margin-right: 8px;
}

.accept-btn {
    background-color: #2d3748;
}

.banner-hidden {
    display: none;
}