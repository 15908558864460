.logo-container {
    max-width: 1100px;
    width: 80%;
    margin: 0 auto;
}


.slick-slide {
    margin: 0px 20px;


}


.slick-slide img {
    width: 100%;


}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid rgba(63, 185, 189, .618);
    box-shadow: 0px 0px 15px -2px #c5c5c5;


}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;



}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;



}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';

}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;

}

[dir='rtl'] .slick-slide {
    float: right;

}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

@media screen and (min-width: 1600px) {
    .logo-container {
        width: 70%;
        max-width: 1100px;
    }

}