@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Baskervville&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Karma:wght@300&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Taviraj:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');

.about-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3em;
    flex-wrap: wrap;
    overflow-x: hidden;

}

.about-container {
    background-image: url(../PNGIMG/Dizajn\ bez\ názvu\ \(14\).png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.about-us-title {
    padding-bottom: 0.1em;
    margin: 50px;
    text-align: center;
    line-height: .9em;
    text-transform: uppercase;
}

.about-us-title u {

    text-decoration-color: rgb(64, 187, 192);
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;


}

.left-pack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.about-left {
    width: 40em;
    margin-right: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;


}


.left-text {
    background-color: rgba(255, 255, 255, 0.756);
    padding: 1em 2em;
    box-shadow: 0 0 10px rgba(45, 45, 45, 0.452);
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid rgba(63, 185, 189, 0.618);
    border-top: 3px solid rgba(63, 185, 189, 0.618);
    line-height: 1.2em;
    font-size: 1.1em;


}


.left-text p:first-child {
    margin-bottom: 2em;
}

.about-left img {
    width: 40em;
    height: 30em;
    box-shadow: 0 0 10px rgba(37, 37, 37, 0.647);
    border: 1.5px solid rgba(45, 45, 45, 0.715);
}



.about-right {
    width: 40em;
    margin-top: 8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.l-right img {
    width: 10em;
    height: 12em;
    border: none;
}


.right1 {

    padding: 1em 2em;
    box-shadow: 0 0 10px rgba(45, 45, 45, 0.452);
    margin-bottom: 2em;
    background-color: rgba(255, 255, 255, 0.816);
    border-bottom: 3px solid rgba(63, 185, 189, 0.618);
    border-top: 3px solid rgba(63, 185, 189, 0.618);
    /* font-family: 'Source Sans Pro', sans-serif; */
    color: rgb(0, 0, 0);
    /* font-family: 'Barlow Semi Condensed', sans-serif; */
    font-size: 1.1em;
    /*  font-weight: 500; */
    /* font-family: 'Mukta Malar', sans-serif; */
    line-height: 1.2em;
    /*  font-family: 'Karma', serif; */
}

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');

.right2 {

    padding: 1em 2em;
    box-shadow: 0 0 10px rgba(45, 45, 45, 0.452);
    background-color: rgba(255, 255, 255, 0.897);
    border-bottom: 3px solid rgba(63, 185, 189, 0.618);
    border-top: 3px solid rgba(63, 185, 189, 0.618);
    color: rgb(0, 0, 0);

    font-size: 1.1em;
    /* font-weight: 500; */
    /*   font-family: 'Mukta Malar', sans-serif; */
    line-height: 1.2em;
    /* font-family: 'Karma', serif; */
}

.right2 a {
    color: black;
    font-weight: 700;
}

.l-left {
    margin-bottom: 2em;
}



.slide-right,
.slide-left {
    opacity: 0;
    transition: opacity .3s ease-in, transform .3s ease-in;
}

.slide-right.slide-right-appear,
.slide-left.slide-left-appear {
    opacity: 1;
    transform: translate(0);
}

.slide-right {
    transform: translate(-50%);

}

.slide-left {
    transform: translate(50%);


}

.slide-in {
    opacity: 0;
    transition: opacity .5s ease-in;
}

.slide-in.slide-in-appear {
    opacity: 1;
}





@media screen and (max-width:1473px) {
    .about-left p {
        max-width: 33em;
    }

    .left-text {
        max-width: 32em;
    }

    .about-left img {
        max-width: 35em;
    }

    .about-right {
        max-width: 35em;
    }
}

@media screen and (max-width:1400px) {
    .about-left {
        margin-right: 1em;
    }

}

@media screen and (max-width:1328px) {


    .about-left {
        max-width: 35em;
        margin-right: 5em;
    }

    .left-text {
        max-width: 35em;
    }

    .about-right {
        max-width: 30em;
    }

}

@media screen and (max-width:1232px) {
    .left-text {
        max-width: 27em;
    }

    .about-left {
        margin-right: 1em;
        margin-top: 2em;
    }

    .about-left img {
        max-width: 31em;
        max-height: 28em;
        margin-bottom: 2em;
    }

    .about-right {
        max-width: 23em;
    }


}

@media screen and (max-width:1056px) {
    .left-text {
        max-width: 23em;
    }

    .about-left {
        margin-right: -1em;
    }

    .about-left img {
        max-width: 27em;
        max-height: 26em;
    }

    .about-right {
        max-width: 18em;
        margin-bottom: 8em;
    }

    .about-box {
        margin-left: 1em;
        margin-right: 1em;
    }

}

@media screen and (max-width:945px) {
    .left-text {
        max-width: 20em;
    }

    .about-left img {
        max-width: 24em;
        max-height: 25em;
    }

    .about-left {
        margin-right: -3em;
    }

    .about-right {
        max-width: 16em;
        margin-bottom: 3em;
    }

}

@media screen and (max-width:816px) {
    .left-text {
        max-width: 34em;

    }

    .about-left {
        margin-right: 1em;
        margin-top: 4em;
    }

    .about-left img {
        max-width: 33em;
        max-height: 25em;

    }

    .about-right {
        max-width: 33em;
        padding-bottom: 4em;
    }


}

@media screen and (max-width:570px) {

    .left-text {
        width: 18em;
        margin-left: 1em;

    }

    .about-left img {
        width: 22em;
        height: 23em;
        margin-left: 3em;
        margin-right: 2em;

    }

    .about-right {

        margin-top: 3em;
    }

}



@media screen and (max-width:440px) {

    .about-left img {
        width: 19em;
        height: 23em;
    }



    .left-text {
        font-size: 1em;

    }

    .right1,
    .right2 {
        width: 70%;
    }




}

@media screen and (max-width: 385px) {
    .left-text {
        width: 70%;
    }

}

@media screen and (max-width: 376px) {
    .left-text {
        width: 60%;
    }

}

@media screen and (max-width: 345px) {
    .left-text {
        width: 70%;
        margin: 1em auto;
    }

    .about-left {
        margin-right: 0;
    }

    .about-left img {
        margin: 1em auto;

    }



}