@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Taviraj:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Baskervville&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Taviraj:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Barlow+Semi+Condensed:wght@300&family=Baskervville&family=Cabin+Sketch:wght@700&family=Cinzel&family=DM+Sans&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Karma:wght@300&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=Mukta+Malar:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Playfair+Display&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Source+Sans+Pro:wght@300&family=Taviraj:wght@300&family=Tilt+Warp&family=Varela+Round&display=swap');


.contact-container {
    background-color: rgb(64, 187, 192);
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
}

.post-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1em 0em;
    flex-wrap: wrap;
    margin-left: 3em;
}

.info-box {
    width: 15em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3em;
}

.info-box p {}

.special-p {
    width: 15em;
    /* height: 15em; */
    margin-right: 3em;
    margin-top: .4em;


}

.special-p,
.info-box {
    opacity: 0;
    transition: opacity .3s ease-in;

}

.info-box h2 {
    font-size: 3em;
    margin: 0;
}

.info-box p {
    background-color: rgb(245, 245, 245);
    color: black;
    padding: 2em 1.8em;
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(223, 223, 223, 0.857);
    height: 6.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-weight: 500; */
    /*   font-family: 'Mukta Malar', sans-serif; */
    line-height: 1.4em;
    /*  font-family: 'Karma', serif; */


}

.special-p p {
    background-color: rgb(245, 245, 245);
    color: black;
    padding: 1em 1em;
    border-radius: 5px;
    box-shadow: 0 0 50px rgba(223, 223, 223, 0.857);
    height: 8.7em;
    box-shadow: 0 0 50px rgba(223, 223, 223, 0.857);
    /*     font-family: 'Mukta Malar', sans-serif; */
    line-height: 1.4em;
    /* font-family: 'Karma', serif; */



}

.special-p h2 {
    font-size: 3em;
    margin: 0;

}

.special-p a {

    color: black;
    font-weight: 700;
}

.special-p.slide-in-appear,
.info-box.slide-in-appear {
    opacity: 1;

}

@media screen and (max-width:640px) {
    .info-box {
        margin-top: 1em;
    }
}

@media screen and (max-width:320px) {
    .info-box {
        margin-top: 1em;
        margin-right: 0;
    }

    .post-cont {
        margin: 0;
    }

    .special-p {
        margin-right: 0;
    }
}