/* .container-gdpr {
    width: 100vw;
} */

#gdpr {
    width: 55em;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;

}

#gdpr h2 {
    position: relative;
    margin-top: 1em;
    padding: 0.3em;
    text-align: center;
}

/* #gdpr h2:after {
        content: "";
        position: absolute;
        background: rgb(50, 183, 190);
        height: 5px;
        width: 8em;
        bottom: 0;
        left: 35%;
        margin-left: auto;
        margin-right: auto;
    } */

.title-underline {

    display: block;
    margin-left: auto;
    margin-right: auto;
}



#gdpr p {
    line-height: 1.5em;
    text-align: justify;
    padding: .5em 0;
}

#gdpr ul li {
    list-style-type: disc;

}

#gdpr ol li {
    padding-left: 0;
}

#gdpr ul li p {
    padding-left: 0;
}



@media screen and (min-width: 2560px) {
    #gdpr {
        width: 70em;
    }

}

@media screen and (max-width: 1024px) {
    #gdpr {
        width: 50em;
    }

}

@media screen and (max-width: 950px) {
    #gdpr {
        width: 45em;
    }

    .points li>ul {
        /* border: 1px solid blue;*/
        text-indent: 0;
        padding-left: 1em;

    }

    #gdpr p {
        text-align: left;
    }
}

@media screen and (max-width: 768px) {
    #gdpr {
        width: 90%;
    }




}

@media screen and (max-width: 525px) {
    #gdpr h2:after {
        left: 25%;

    }
}

@media screen and (max-width: 486px) {
    .points li>ul {
        /* border: 1px solid blue;*/
        text-indent: 0;
        padding-left: 0;

    }
}