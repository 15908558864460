.discounts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4em auto 0;
    border-top: 1px solid rgba(156, 156, 156, 0.66);
    width: 70%;

}

.discounts-header h1 {

    border-bottom: 3px solid rgb(64, 187, 192);
    padding-bottom: 0.1em;
    margin: 50px;
    text-align: center;
    line-height: .9em;



}

.discounts-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: .5em;


}

.discounts-images .one-img {
    width: 18em;
    height: 18em;
    overflow: hidden;
    border: 2px solid rgba(77, 77, 77, 0.44);
    box-shadow: 0 10px 20px rgba(33, 33, 33, 0.593);
    margin: 1em;
}

.discounts-images img {
    width: 18em;
    height: 18em;
    object-fit: cover;
    transition: transform 0.3s ease-in
}



/* .discounts-images a:nth-child(3) img {
    border: 2px solid rgba(77, 77, 77, 0.44);
    box-shadow: 0 10px 20px rgba(33, 33, 33, 0.593);
    margin-bottom: 2em;
    animation: move infinite 7s linear;
} */

/* .discounts-images .one-img:nth-child(3) a img {

    width: 90%;
    margin: 0 auto;

} */

.discounts-images .one-img:nth-child(1) a img {
    object-position: 50% 60%;
}

.discounts-images a img:hover {
    transform: scale(1.06);

}


@keyframes move {
    0% {
        object-position: bottom 0 left 0;
    }

    100% {
        object-position: bottom 0 left 100%;
    }

    0% {
        object-position: bottom 0;
    }

}



/* .discounts-images img:hover {
    transform: scale(1.05);
    transition: ease-in-out .3s;
} */



@media screen and (max-width:500px) {

    .discounts-header h1 {
        font-size: 1.5em;


    }

}