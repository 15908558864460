.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: aliceblue;
    background-color: rgb(64, 187, 192);
    width: 100%;
}



.footer-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: aliceblue;
    background-color: rgb(64, 187, 192);
    margin: 2em;
    position: relative;
    width: 100%;
}

.footer-right {
    display: flex;
    flex-direction: column;
}

.formular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.textarea-wrapper {
    width: 100%;
    position: relative;

}

.form-textarea {
    width: 100%;
    border: none;
    border-bottom: 3px solid rgb(86, 86, 86);


}

.formular a:hover {
    color: white;

}

.form-textarea:focus {
    outline: none;
}

.form-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;


}

.input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 1em;
    justify-content: center;
    align-items: center;
}


.error-label {
    position: absolute;
    bottom: -10%;
    left: 5px;
    color: rgb(255, 217, 224);




}

.form-inputs input.error-border {
    border: 2px solid red;
}

.form-textarea.error-border {
    border: 2px solid red;
}

.error-label-text {
    margin-left: .5em;
    color: rgb(255, 217, 224);
}



@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Cabin+Sketch:wght@700&family=Cinzel&family=Domine:wght@600&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost:ital,wght@0,500;0,600;1,500&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Poppins:wght@600&family=Rock+Salt&family=Rubik+Vinyl&family=Tilt+Warp&family=Varela+Round&display=swap');

.footer-right p,
.footer-right h3,
.footer-right h1,
.footer-right h2 {
    line-height: .1em;
    font-family: 'Poppins', sans-serif;
    padding: 20px 0 20px;
}

.footer-right h1 {
    font-style: italic;
    font-size: 1.5em;
    padding: 20px 0 20px;


}

.footer-right p,
h3 {
    padding: 20px 0 20px;
    font-weight: 600;
    font-size: 1.2em;
    color: rgb(0, 0, 0);


}

.formular p:last-of-type {
    font-size: 1rem;
    align-self: flex-start;
}

.footer-right h2 {
    font-size: 1.1em;
    padding: 20px 0 20px;
}

.form-inputs input {
    width: 20em;
    border: none;
    border-bottom: 2px solid rgb(94, 94, 94);
    padding: .5em;
    margin-bottom: 1em;
    height: 3em;
    padding-left: 2em;


}


iframe {
    margin-right: 4em;

}







.form-inputs input:first-child {
    margin-right: 2em;
}

.form-inputs input:focus {
    border: 2px solid white;
    outline: none;
    border-bottom: 2px solid rgb(94, 94, 94);
}

.formular button {
    width: 8em;
    margin-top: 1em;
    padding: 1em;
    border-radius: 1px;
    border: none;
    border-bottom: 2px solid rgb(94, 94, 94);
    cursor: pointer;
    font-weight: 600;

}

.formular button:hover {

    border: 1px solid rgb(34, 34, 34);
    transition: .1s linear;

}

.footer-right a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.linkfb a {
    color: rgb(0, 0, 0);
    margin: 1em .1em;
    font-size: 1.5em;
    padding: 20px 0 20px;
}

.linkfb a:hover {
    color: aliceblue;
}

.linkfb a:hover {
    color: rgb(221, 221, 221);
    transition: .15s;
}

.form-checbox {
    position: relative;

}

#check-gdpr {
    width: 15px;
    height: 15px;
}

.form-checbox label {
    font-size: 1.2rem;
    align-self: flex-start;
    font-weight: 600;
    padding-left: 5px;
    cursor: pointer;

}

.form-checbox a {
    color: white;
}

.form-checbox a:hover {
    color: black;
    transition: ease-in-out 0.5s;
}

.subfooter {
    color: aliceblue;
    background-color: rgb(51, 150, 154);
}

.subfooter ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;

}

.subfooter ul li {
    margin: .3em;

}

.subfooter ul li span {
    padding: 20px 20px 0px;
}

.subfooter ul li a {
    text-decoration: none;
    color: aliceblue;
    font-weight: 600;
}

.subfooter ul li a:hover {
    color: black;
    transition: ease-in-out 0.5s;
}


.subfooter .right {
    margin-right: 0;
    margin-left: auto;
    padding-right: 5px;
}



/* 
.subfooter span {
    margin: 1em;
    padding-top: 5px;
}

.subfooter a {
    text-decoration: none;
    color: aliceblue;
    font-weight: 600;
}

.subfooter a:hover {
    color: black;
    transition: ease-in-out 0.5s;
} */
@media screen and (max-width:1410px) {
    .footer-right h1 {
        line-height: 1.2em;
    }

    .footer-right {
        align-items: center;
        justify-content: center;

    }

    iframe {
        margin-top: 4em;
        margin-bottom: 3em;
        margin-right: 2em;
    }

    .formular p:last-of-type {
        align-self: center;
    }
}


@media screen and (max-width:1100px) {
    iframe {

        margin-right: 0em;
    }


}


@media screen and (max-width:872px) {
    .form-inputs input:first-child {
        margin-right: 2em;
    }
}

@media screen and (max-width:710px) {
    .form-inputs input {
        width: 15em;
    }

    .formular p {
        line-height: 1.3em;
        text-align: center;


    }

    .error-label {

        width: 300px;
        font-size: 14px;
    }

    .form-checbox label {
        font-size: 1em;
    }



}

@media screen and (max-width:575px) {
    .form-inputs {
        flex-wrap: wrap;
    }

    .form-inputs input:first-child {
        margin-right: 0em;
    }

    .form-inputs input {
        width: 30em;
    }


}


@media screen and (max-width:535px) {
    iframe {
        width: 350px;
        height: 300px;
    }

    .footer-right h3,
    .footer-right p {
        font-size: 1em;
    }


}

@media screen and (max-width: 485px) {


    .subfooter ul {
        flex-direction: column;
        align-items: center;


    }

    .subfooter ul li span {
        padding: 20px 20px 0px;
        display: none;
    }

    .subfooter .right {
        margin-right: 0;
        margin-top: 1em;

    }
}

@media screen and (max-width:475px) {
    .form-inputs input {
        width: 25em;
    }


}


@media screen and (max-width:450px) {
    iframe {
        width: 260px;
        height: 210px;
    }


    .footer-right h1 {
        font-size: 1.5em;
    }



    .footer-right h1 {
        font-size: 1.2em;
    }

    .footer-right h3 {
        font-size: .9em;
    }

    .footer-right p {
        font-size: .9em;
    }


}


@media screen and (max-width:400px) {
    .form-inputs input {
        width: 18em;
    }

    .error-label {
        font-size: 14px;
    }

    .form-checbox {
        text-align: center;
    }

}

@media screen and (max-width:376px) {

    .footer-right h1 {
        font-size: 1.3em;
    }

    .footer-right h3 {
        font-size: .9em;
        line-height: 1.5em;
        text-align: center;
    }



}