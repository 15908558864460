/* .recap-cont {
    width: 290px;
}


.res-form input {
    display: flex;
    flex-direction: column;
    padding: .3rem .5rem;
}

.error-msg {
    color: red;
}

.btn-time option.disabled {
    color: grey;
    text-decoration: 1px black line-through;
} */

.reservation-form {
    margin: 2rem auto;
    position: relative;
}

.reservation-form h2 {
    text-align: center;
}



.recap-cont {
    width: 290px;
    margin: 2rem auto;
}

.reservation-form .recap-cont {
    width: 100%;
    max-width: 300px;

}

.res-form-container {

    margin: auto;
    width: 500px;
    background-color: var(--color-tertiary);
    border-radius: 10px;
}

/* .res-form input {
    display: flex;
    padding: .3rem .5rem;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;


} */

.res-form {
    display: flex;
    flex-direction: column;
    padding: .3rem .5rem;
    box-sizing: border-box;
    width: 80%;
    /* align-items: center; */
    justify-content: center;
    margin: auto;
}

.res-form a {
    text-decoration: none;
    color: rgba(118, 11, 11, 1);
}

.res-form label {
    cursor: pointer;
}



/* .res-form-input {
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;


} */

.form-change {
    -webkit-appearance: none;
    padding: 10px 10px;
    display: block;
    width: 100%;
    height: auto;
    font-family: Roboto, sans-serif;
    font-size: 15.75px;
    line-height: 21px;
    font-weight: 300;
    vertical-align: bottom;
    color: #454648;
    background-color: #dfe2ee;
    border: 1px solid #dfe2ee;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    transition: color .5s, background-color .5s, border-color .5s, box-shadow .5s
}

.form-change-group.anim-effect {
    margin-bottom: 20px;
    padding-top: 23px;
    position: relative
}

.form-change-group.anim-effect .form-change-label {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    color: #848798;
    -webkit-transform: translate(11px, 34px);
    /* transform: translate(11px, 34px); */
    transition: font-size .25s, -webkit-transform .25s;
    transition: transform .25s, font-size .25s;
    /* transition: transform .25s, font-size .25s, -webkit-transform .25s; */
    z-index: 1;
    pointer-events: none
}

form .form-change-group:before {
    content: "";
    display: table;

}

.form-change.filled,
.form-change:focus {
    background-color: #fff;
    border-color: #dfe2ee
}

.form-change:hover {
    background-color: #fff;
    border-color: #dfe2ee
}

.form-change-group.anim-effect.filled .form-change-label,
.form-change-group.anim-effect.focus .form-change-label {
    font-size: 11.5px;
    line-height: 12px;
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px)
}

.form-change-group.anim-effect.filled .form-change,
.form-change-group.anim-effect.focus .form-change {
    background-color: #fff;
    border-color: #dfe2ee;
}

.res-form-input input {
    padding: .3rem .5rem;
    gap: 5px;
    height: 25px;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid grey;

}

.res-form-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

#res-form-check-gdpr {
    width: 25px;
    height: 25px;
}


.reservation-form .buttons div {
    justify-content: center;

}

.res-form-container .send-res {
    margin: 1rem;
    border: none;
    align-self: center;
}


.error-msg {
    color: red;
}

.btn-time option.disabled {
    color: grey;
    text-decoration: 1px black line-through;
}

.bgImg {
    background-repeat: no-repeat;
    background-size: contain;
    height: 450px;
    opacity: .7;
    margin-top: 1rem;

}





@media screen and (max-width: 825px) {

    .form-change {
        width: 100%;


    }

    .res-form-container {
        width: 80%;

    }

}