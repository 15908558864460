body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: rgb(64, 187, 192);
  --color-secondary: rgb(250, 196, 196);
  --color-tertiary: rgb(233, 233, 233);
  --color-button: rgb(39, 153, 169);

  --highlight-transform-default: matrix(1.1, -0.02, -0.44, 0.9, 8, 0);
  /* --highlight-transform-alt: matrix(1.3, -0.02, -0.44, 0.9, 8, 0); */
  --highlight-transform-alt: scale(1.07, 1.05) skewX(-15deg);
}