@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Cabin+Sketch:wght@700&family=Cinzel&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Jost&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Rock+Salt&family=Rubik+Vinyl&family=Tilt+Warp&family=Varela+Round&display=swap');

.top-nav {
    color: rgb(255, 255, 251);
    background-color: rgb(64, 187, 192);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 100%;
    height: 3.1em;
    font-family: 'Jost', sans-serif;
}

.top-nav a {
    text-decoration: none;
    color: rgb(255, 255, 251)
}

.hours {
    margin-left: 8em;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
}

.contact {
    margin-right: 8em;
}

.contact a:hover {
    color: rgb(56, 56, 56);
}

.top-nav p {
    text-align: center;
}

@media screen and (max-width:785px) {

    .hours {
        margin-left: 5em;
    }

    .contact {
        margin-right: 5em;
    }
}

@media screen and (max-width:550px) {
    .top-nav p {
        font-size: .8em;
        margin-right: 2em;
    }

    .contact a {
        margin-right: .7em;
    }
}