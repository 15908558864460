.img-responsive {
  transform: scale(1);

  &:hover {
    transition: 0.03 ease-in-out;
    transform: scale(1.1);
  }
}

@media screen and (max-width: 540px) {
  .img-responsive {
    &:hover {
      transform: scale(1.04);
    }
  }
}
