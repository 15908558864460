.appointment-container {
    /* background-color: #E9E9E9; */
    width: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
}


.appointment-container section {
    display: flex;
    background-color: #E9E9E9;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    gap: 20px;
    width: 800px;


}

.reserv-container {
    display: flex;
    background-color: rgba(39, 153, 169, .6);
    padding-top: 1rem;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.recap {
    border-radius: 10px;
    background-color: rgba(250, 196, 196, 0.3);
    padding: 1rem;
    /* display: flex;
    flex-direction: column;
    gap: 10px; */


}



.recap-info>div {
    display: flex;
    justify-content: space-between;


}

.recap-msg {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    background-color: #ffe7b7;
    padding: .3rem;
    gap: 5px;
    width: 100%;
    max-width: max-content;


}

.recap-info {
    display: flex;
    flex-direction: column;

}




.calendar {
    border: none;
    cursor: pointer;
    width: 300px;
    font-size: 1rem;
    color: white;
    background-color: rgb(20, 166, 173);
    text-align: start;
    display: flex;
    padding: 12px;
    box-sizing: border-box;



    /* margin-bottom: 1em;
    padding: .25em;
    border: 0;
    border-bottom: 2px solid currentcolor;
    font-weight: bold;
    letter-spacing: .15em;
    border-radius: 0; */
}

.calendar::placeholder {
    color: white;
}

.btn-time {
    background-color: rgb(20, 166, 173);
    color: white;
    padding: 12px;
    width: 300px;
    border: none;
    font-size: 1rem;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: none;
    outline: none;
    /* margin-bottom: 1em;
    padding: .25em;
    border: 0;
    border-bottom: 2px solid currentcolor;
    font-weight: bold;
    letter-spacing: .15em;
    border-radius: 0; */
    box-sizing: border-box;
    text-align: start;
    cursor: pointer;
}

.btn-time option {
    background-color: white;
    color: black;
}

.reserve-date,
.reserve-time {
    margin: 1rem 0;
    width: 70%;
    position: relative;


}

.reserve-date::after,
.reserve-time::after {
    content: "";
    position: absolute;
    width: 70%;
    right: -40%;
    top: 40%;
    pointer-events: none;
    font-size: 1rem;
    background-image: url("./down-arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;





}

.buttons {
    width: 100%;
    margin: 1rem 0;

}

.buttons div {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    gap: 5px;
}

.btn-next,
.btn-prev {
    background-color: #E9E9E9;
    border: none;
    padding: .3rem;
    width: 150px;
    height: 35px;
    cursor: pointer;
}

.btn-next:hover,
.btn-prev:hover {
    background-color: #c9c6c6;
    ;
}


.tooltip-container {
    position: relative;
    display: block;


}

.tooltip-container .tooltip {
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    top: 40px;
    /* background-color: var(--color-tertiary); */
    padding: 5px;
    max-width: 280px;

}

.btn-next:hover .tooltip {
    visibility: visible;
}



.tooltip p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;

}

@media screen and (max-width: 825px) {

    .appointment-container section {
        flex-direction: column;
        width: 80%;
        padding: 1rem 0;
    }

    .buttons div {
        width: 90%;
        justify-content: center;
    }

    .calendar,
    .btn-time {

        width: 150%;
    }

}

@media screen and (max-width: 320px) {

    .calendar,
    .btn-time {

        width: 140%;
        border: rgba(255, 255, 255, .5) 1px solid;
    }


    .reserv-container {
        align-items: flex-start;

    }


}