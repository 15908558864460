body {
  box-sizing: border-box;

}

* {
  margin: 0;

}

.container-leaflet button {
  border: none;
  background-color: transparent;
}


.container-leaflet {
  display: flex;
  text-align: center;
  margin: 1rem auto;
  padding: 20px;
  font-size: 24px;
  width: 75%;
  flex-direction: column;
  align-items: center;
  border-top: 3px solid rgba(63, 185, 189, 0.618);

}


.img-responsive {
  cursor: pointer;
  border-bottom: 2px solid rgba(63, 185, 189, 0.618);
  margin: 5px;
  box-shadow: 13px 12px 15px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1570px) {

  .container-leaflet {
    width: 1000px;

  }

}

@media screen and (max-width:540px) {
  .img-responsive {
    margin: 0 -2em;
  }

  .container-leaflet {
    width: 40%;

  }
}