.nav-container {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    min-height: 5em;
}

.nav-container img {
    width: 6em;
    height: 3em;
    margin-left: 10em;
}

.nav-list {
    display: flex;
    flex-direction: row;
    margin-right: 8em;
}

.nav-list li {
    font-weight: 600;

}

.nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
}


@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Allura&family=Annie+Use+Your+Telescope&family=Anton&family=Babylonica&family=Cabin+Sketch:wght@700&family=Cinzel&family=Homemade+Apple&family=Indie+Flower&family=Josefin+Sans:ital,wght@1,600&family=Julee&family=Kaushan+Script&family=Lobster+Two:ital@1&family=Lora:wght@400;700&family=Merriweather:wght@300&family=PT+Serif:ital,wght@0,700;1,700&family=Pacifico&family=Patrick+Hand&family=Rock+Salt&family=Rubik+Vinyl&family=Tilt+Warp&family=Varela+Round&display=swap');


.nav-list li {
    margin-left: 2em;
    list-style: none;
    cursor: pointer;
    font-family: 'Varela Round', sans-serif;

}

.nav-list li:hover {
    color: rgb(64, 187, 192);



}

.nav-left h2 {
    display: none;
    cursor: pointer;
}

.nav-left h2:hover {

    border-radius: 5px;
    transition: all .1s linear;
}

@media screen and (max-width:1000px) {
    .nav-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 14em;
    }

    .nav-left {
        justify-content: space-between;
        align-items: center;
        margin-top: 1em;
        margin-bottom: -1em;
        width: 100%;

    }

    .nav-list {
        justify-content: center;
        flex-direction: column;
        min-height: 0em;
        margin-top: 2em;
    }

    .nav-list li {
        border-bottom: 1px solid rgb(64, 187, 192);
        width: 300px;
        text-align: center;
        margin-left: 5em;
        font-weight: 600;

    }

    .nav-list li:last-child {
        border-bottom: none;
    }

    .nav-left h2 {
        display: flex;
        margin-left: 5em;
        margin-right: 3em;
        padding: .2em;
        background-color: rgb(103, 193, 196);
        border-radius: 5px;
    }

    .nav-container img {
        margin-left: 3em;
    }

    .show {
        height: 6em;
        transition: all 0.25s linear;
    }

    .hide {
        height: 0px;
        overflow: hidden;
        transition: all 0.25s linear;
    }

}

@media screen and (max-width:760px) {
    .nav-left h2 {
        margin-left: 3em;
    }
}

@media screen and (max-width:550px) {
    .nav-container img {
        /* margin-right: 4em; */
    }



    .nav-left {
        width: 110%;
    }
}