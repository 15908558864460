#coupons {
    background-color: rgb(64, 187, 192);
    margin: 2em 0;
    width: 100%;
}

#coupons h2 {
    text-align: center;
    font-size: 2em;
    margin: 1em;
    padding-top: 2em;
    line-height: 1.1em;
}

.coupons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}

.coupons-img img {
    width: 18em;
    height: 11.45em;
    object-fit: contain;
    margin: 2em;
    border: 2px solid rgba(77, 77, 77, 0.65);
    box-shadow: 0 10px 20px rgba(33, 33, 33, 0.593);
    background-color: white;
}

.coupons-img img:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
}

/* gift coupons */

#gift-coupons {
    margin: 2em 0;
}

.gift {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gift-container {
    width: 80%;
    background-color: rgba(64, 187, 192, 0.2);
    margin: 5em auto;
    box-shadow: 2px 3px 13px 5px rgba(0, 0, 0, 0.1);
}

.gift-container h2 {
    text-align: left;
    font-size: 2em;
    width: 65%;
    padding: 1em;
}

.gift-container p {
    font-size: 1.1em;
    padding: .5em 2em;
}

.gift img {
    width: 100%;
    max-width: 30em;
    margin: 2em;
    border: 1px solid rgba(64, 187, 192, 0.6);
}

@media screen and (max-width: 1200px) {

    .gift-container h2,
    .gift-container p {
        text-align: center;
        width: 90%;
    }
}

@media screen and (max-width: 620px) {
    .gift img {
        width: 90%;
    }

    .gift-container h2 {
        font-size: 1.7em;
    }

}

@media screen and (max-width: 555px) {
    .gift-container h2 {
        font-size: 1.4em;
    }

    .gift-container p {
        padding: .5em;
        margin: 0;
    }
}

@media screen and (max-width: 320px) {
    .gift-container h2 {
        font-size: 1.2m;
    }

    .gift-container p {
        padding: .5em;
        margin: 0;
    }
}