.dermo-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.dermo-box {
    margin: 4em;
    margin-bottom: 2em;
}

.dermo-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 4em;

}

.dermo-left {
    max-width: 40em;
    margin: 0 4em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.left-h3 {
    max-width: 30em;
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 30px;
}

.left-p {
    line-height: 1.5em;
    margin-top: 30px;
}

.dermo-box img {
    width: 43em;
    height: 30em;
    margin-top: 35px;
}

.scale-up {
    transform: scale(.8);
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
    opacity: 0;
}

.scale-up.scale-up-appear {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
    opacity: 1;
}

.scale-up.scale-final {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}

.dermo-top img {
    box-shadow: 0 0 30px rgba(38, 38, 38, 0.64);
    border: 1px solid rgba(34, 34, 34, 0.669);

}

.dermo-left h4 {
    box-shadow: 0px 0px 30px rgba(97, 97, 97, 0.494);
    padding: 2em 5em;
    background-color: rgb(255, 255, 255);
}

.dermo-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 3em;
    /* border-bottom: 1px solid rgba(156, 156, 156, 0.66); */
}

.dermo-footer p {
    text-align: start;
    max-width: 23em;
    margin-right: 2em;
    margin-top: 2em;
    line-height: 1.6em;
    box-shadow: 0 5px 20px rgba(101, 101, 101, 0.35);
    padding: 1.5em;
}

.brands-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.brands-title h1 {
    border-bottom: 3px solid rgb(64, 187, 192);
    padding-bottom: .2em;
    margin: 0 0 30px;
    line-height: .9em;
}

@media screen and (max-width:1400px) {
    .dermo-left {
        margin-right: 0em;
    }
}

@media screen and (max-width:1200px) {

    .dermo-top img {
        max-width: 40em;
        max-height: 30em;
    }

    .dermo-footer p {
        margin-right: 2em;

    }

    .dermo-left {
        margin-right: 4em;
    }
}

@media screen and (max-width:710px) {
    .zlavy-images img {
        margin-right: 0em;
    }
}

@media screen and (max-width:665px) {

    .dermo-top img {
        max-width: 30em;
        max-height: 20em;
    }
}

@media screen and (max-width:500px) {
    .brands-title h1 {
        font-size: 1.5em;
    }

    .dermo-top img {
        max-width: 25em;
        max-height: 15em;
    }

    .dermo-footer {
        margin: 0 auto;
        align-items: center;

    }

    .dermo-footer p {
        max-width: 20em;
        min-width: 13em;
        width: 70%;
        margin: 1em auto;

    }

    .slide-up {
        transform: translateY(50%);
    }
}

@media screen and (max-width:425px) {

    .dermo-top img {
        max-width: 20em;
        max-height: 15em;
    }
}