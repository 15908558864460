.card-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 400px;
    margin-top: 1rem;
    background-color: var(--color-secondary);
    border-radius: 10px;


}

.card-header {
    margin-bottom: 1rem;
    background-color: white;
    margin: 0;
    padding: 1rem 2rem;
    z-index: 1;
    border-top: 3px solid var(--color-tertiary);
    border-left: 3px solid var(--color-tertiary);
    border-right: 3px solid var(--color-tertiary);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;


}

.card-heading {
    border-bottom: 3px solid #ccc;
    padding: 1rem 0;
}

.card-header h3 {
    font-weight: 700;
    font-size: 18px;
}

.price {
    color: white;

}




.info {
    display: flex;
    justify-content: space-between;

}


.info div {
    display: flex;
    gap: .5rem;
}



.icon {
    font-size: 1.6rem;
}

.card-footer {
    background-color: var(--color-secondary);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 180px;
    border-radius: 10px;

}

.send-res {
    text-decoration: none;
    display: inline-block;
    color: white;
    font-weight: 700;
    color: white;
    width: 200px;
    padding: .5rem;
    background: #2799A9;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;


}

.send-res:hover {
    box-shadow: inset 0px -4px 4px rgba(0, 0, 0, 0.25);
    transform: scale(1.01);
}

.text {
    line-height: 1.6;
    width: 100%;
    max-width: 310px;
    align-self: flex-start;
}


@media screen and (max-width: 825px) {


    .card-container {

        width: 50%;
        min-width: 400px;
        margin: 0 auto;
    }


}

@media screen and (max-width: 525px) {

    .card-container {
        min-width: 350px;
    }

    .card-footer {
        padding: 1rem;

    }


    .card-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-header h3 {
        text-align: center;
    }

    .info {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

    }

    .text {
        line-height: 1.4;
    }

    .card-heading {
        text-align: center;
    }

}

@media screen and (max-width: 375px) {
    .card-container {
        min-width: 300px;
    }
}